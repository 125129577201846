<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.paymentType')" filterable clearable
      :size="size" class="w-100" :disabled="disabled">
      <el-option v-for="(paymentType,index) in paymentTypes" :key="'paymentTypes-' + index" :label="paymentType.name" :value="paymentType.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      disabled:{
        default: false
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.paymentTypes && this.paymentTypes.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        paymentTypes: 'paymentTypes/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'paymentTypes/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
